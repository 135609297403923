<template>
  <div>
    <div class="row mb-0 mt-1">
      <b-nav>
        <b-nav-item
          v-for="group in groups"
          :key="group.label"
          :active="selectedGroup === group"
          @click="selectGroup(group)"
          >{{ group.label }}
        </b-nav-item>
      </b-nav>
    </div>

    <v-progress-linear
      v-if="isBusy"
      indeterminate
      color="primary"
      class="mb-5 mt-2"
    />

    <div v-for="(n, i) in rows" :key="n" class="row">
      <div v-for="(k, j) in cols" :key="k" class="col">
        <Connector
          v-if="connectors.length > i * cols + j"
          :connector="connectors[i * cols + j]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Integrations from "@/components/Projects/Integrations/Overview/integrations";
import Connector from "@/components/Projects/Integrations/Overview/Connector";
import { mapGetters } from "vuex";

export default {
  name: "ProjectsIntegrationsOverviewSelectConnector",
  components: {
    Connector
  },
  data() {
    return {
      groups: [
        {
          label: this.$t("integrations.groupAll"),
          tags: []
        },
        {
          label: this.$t("integrations.groupFilesApis"),
          tags: ["api"]
        },
        {
          label: this.$t("integrations.groupErps"),
          tags: ["erp"]
        },
        {
          label: this.$t("integrations.groupCrm"),
          tags: ["crm"]
        },
        {
          label: this.$t("integrations.groupPim"),
          tags: ["pim"]
        },
        {
          label: this.$t("integrations.groupShops"),
          tags: ["shop"]
        }
      ],
      selectedGroup: "",
      connectors: [],
      rows: 0,
      cols: 3,
      // Meta info
      meta: [],
      page: 1,
      totalPages: 10,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      totalRecords: 0,
      isBusy: false
    };
  },
  computed: {
    ...mapGetters(["selectedClient"])
  },
  watch: {
    selectedClient: function () {
      this.loadTags();
    }
  },
  mounted() {
    this.loadTags();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.projectIntegrations"),
        route: ""
      },
      {
        title: this.$t("menu.projectIntegrationsOverview"),
        route: "/project/integrations"
      },
      {
        title: this.$t("integrations.selectConnector"),
        route: "/project/integrations/select-connector"
      }
    ]);
  },
  methods: {
    loadTags() {
      if (this.selectedClient === null) {
        this.connectors = [];
        return;
      }
      this.selectedGroup = "";
      this.selectGroup(this.groups[0]);
    },
    selectGroup(group) {
      if (this.selectedGroup === group) return;
      this.selectedGroup = group;
      this.loadConnectors(group);
    },
    loadConnectors(group) {
      if (this.selectedClient === null) {
        this.connectors = [];
        return;
      }

      this.isBusy = true;
      const params = this.getRequestParams(this.page, this.pageSize);

      if (group.tags.length > 0) {
        params["tags"] = group.tags[0];
      }

      Integrations.getAll(params)
        .then(response => {
          this.connectors = response.data.data;
          this.rows = Math.ceil(this.connectors.length / this.cols);
          this.isBusy = false;
        })
        .catch(error => {
          console.log(error);
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    getRequestParams(page, pageSize) {
      let params = {};
      if (page) params["page"] = page;
      if (pageSize) params["size"] = pageSize;
      return params;
    }
  }
};
</script>

<style scoped></style>
