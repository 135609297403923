<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="col-md-12">
          <div class="p-20">
            <h1>Work In Progress</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "SysWorkInProgress",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Work In Progress",
        route: ""
      }
    ]);
  }
};
</script>
