<template>
  <div class="client-details">
    <integrations v-if="$route.name.includes('projectIntegrationsOverview')" />
    <wip v-else />
  </div>
</template>

<script>
import integrations from "@/components/Projects/Integrations/Overview/Index";
import wip from "@/components/SYS/WIP";

export default {
  components: {
    integrations,
    wip
  }
};
</script>
