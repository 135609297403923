<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="mb-5 ml-10">
        <span
          class="text-dark font-weight-bold text-hover-primary font-size-h4"
        >
          {{ title }}
        </span>
      </div>

      <div class="d-flex align-items-center">
        <!--begin::Pic-->
        <div class="flex-shrink-0 mr-4">
          <div class="symbol symbol-circle symbol-lg-75 border">
            <img :src="imgPath" />
          </div>
        </div>
        <!--end::Pic-->
        <!--begin::Title-->
        <div class="d-flex flex-column">
          <span class="text-muted font-weight-bold">{{ subtitle }}</span>
        </div>

        <!--end::Title-->
      </div>

      <button class="btn btn-primary btn-hover mt-6" @click="createIntegration">
        {{ $t("integrations.create") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectsIntegrationsOverviewConnector",
  props: ["connector"],
  computed: {
    title: function () {
      if (this.connector.currentLabel === null) {
        return "";
      }
      return this.connector.currentLabel;
    },
    subtitle: function () {
      if (this.connector.currentDescription === null) {
        return "";
      }
      return this.connector.currentDescription;
    },
    imgPath() {
      let path = "";
      if (this.connector?.name === "shopware") {
        path = "media/integration-logos/shopware-5.png";
      } else if (this.connector?.name === "enventa") {
        path = "media/integration-logos/enventa.png";
      } else {
        path = "media/project-logos/2.png";
      }
      return path;
    }
  },
  methods: {
    createIntegration() {
      this.$router.push({
        name: "projectIntegrationsOverviewCreateLoginDetails",
        params: { id: this.connector.id }
      });
    }
  }
};
</script>
