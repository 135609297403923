<template>
  <div>
    <div class="d-flex flex-row">
      <div
        id="kt_profile_aside"
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-body pt-4">
            <div class="font-weight-bolder font-size-h4 text-dark-75">
              <span class="ml-3">{{ $t("integrations.create") }}</span>
            </div>

            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded mt-3"
            >
              <div
                v-for="(item, i) in tabNavigation"
                :key="i"
                class="navi-item mb-2"
              >
                <router-link
                  v-slot="{ href, isActive }"
                  :to="{ name: item.route }"
                >
                  <a
                    :id="item.route"
                    :href="href"
                    class="navi-link inactiveLink"
                    :class="{ active: isActive }"
                  >
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-lg-8 project-details">
        <LoginDetails
          v-if="$route.name === 'projectIntegrationsOverviewCreateLoginDetails'"
          :data="data"
        />
        <SelectData
          v-else-if="
            $route.name === 'projectIntegrationsOverviewCreateSelectData'
          "
          :data="data"
        />
        <AnalyzeData
          v-else-if="
            $route.name === 'projectIntegrationsOverviewCreateAnalyzeData'
          "
          :data="data"
        />
        <BasicConfig
          v-else-if="
            $route.name === 'projectIntegrationsOverviewCreateBasicConfig'
          "
          :data="data"
        />
        <TableConfig
          v-else-if="
            $route.name === 'projectIntegrationsOverviewCreateTableConfig'
          "
          :data="data"
        />
        <Save
          v-else-if="$route.name === 'projectIntegrationsOverviewCreateSave'"
          :data="data"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoginDetails from "@/components/Projects/Integrations/Overview/Create/Steps/LoginDetails";
import SelectData from "@/components/Projects/Integrations/Overview/Create/Steps/SelectData";
import AnalyzeData from "@/components/Projects/Integrations/Overview/Create/Steps/AnalyseData";
import BasicConfig from "@/components/Projects/Integrations/Overview/Create/Steps/BasicConfiguration";
import TableConfig from "@/components/Projects/Integrations/Overview/Create/Steps/TableConfiguration";
import Save from "@/components/Projects/Integrations/Overview/Create/Steps/SaveIntegration";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "ProjectsIntegrationsOverviewCreateCreate",
  components: {
    LoginDetails,
    SelectData,
    AnalyzeData,
    BasicConfig,
    TableConfig,
    Save
  },
  data() {
    return {
      data: {
        step: 0,

        // LoginDetails
        authSuccess: false,
        config: [],
        configValues: {
          url: "http://192.168.178.36:8888/shopware5",
          username: "demo",
          apiKey: "f2pzsheatQ6REiaahMwBp4rffL7uxTQS8SQkPN4p"
        },

        // SelectData
        possibilities: [],
        selectedPossibilities: [
          {
            resource: "",
            method: ""
          }
        ]
      },

      tabNavigation: [
        {
          route: "projectIntegrationsOverviewCreateLoginDetails",
          label: "integrations.loginInformation"
        },
        {
          route: "projectIntegrationsOverviewCreateSelectData",
          label: "integrations.selectData"
        },
        {
          route: "projectIntegrationsOverviewCreateAnalyzeData",
          label: "integrations.analyzeData"
        },
        {
          route: "projectIntegrationsOverviewCreateBasicConfig",
          label: "integrations.basicConfig"
        },
        {
          route: "projectIntegrationsOverviewCreateTableConfig",
          label: "integrations.tableConfig"
        },
        {
          route: "projectIntegrationsOverviewCreateSave",
          label: "integrations.saveIntegration"
        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.projectIntegrations"),
        route: ""
      },
      {
        title: this.$t("menu.projectIntegrationsOverview"),
        route: "/project/integrations"
      },
      {
        title: this.$t("integrations.create"),
        route: "/project/integrations/create/loginDetails"
      }
    ]);
  }
};
</script>

<style scoped>
.inactiveLink {
  pointer-events: none;
  cursor: default;
}
</style>
