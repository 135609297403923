import ApiService from "@/core/services/api.service";

ApiService.setHeader();
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}

class Integrations {
  getAll(params) {
    let options = "?page=" + params.page + "&perPage=" + params.size;

    if (params.tags) {
      options += "&tags=" + params.tags;
    }

    return ApiService.get(url, "integrations" + options);
  }

  getConfig(id) {
    return ApiService.get(url, "integrations/" + id + "/config");
  }

  getPossibilities(id) {
    return ApiService.get(url, "integrations/" + id + "/possibilities");
  }

  test(id, config) {
    return ApiService.post(url + "/integrations/" + id + "/test", {
      config: config
    });
  }
}

export default new Integrations();
