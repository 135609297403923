var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"flex-row-auto offcanvas-mobile w-300px w-xl-350px",attrs:{"id":"kt_profile_aside"}},[_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-body pt-4"},[_c('div',{staticClass:"font-weight-bolder font-size-h4 text-dark-75"},[_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.$t("integrations.create")))])]),_c('div',{staticClass:"navi navi-bold navi-hover navi-active navi-link-rounded mt-3"},_vm._l((_vm.tabNavigation),function(item,i){return _c('div',{key:i,staticClass:"navi-item mb-2"},[_c('router-link',{attrs:{"to":{ name: item.route }},scopedSlots:_vm._u([{key:"default",fn:function({ href, isActive }){return [_c('a',{staticClass:"navi-link inactiveLink",class:{ active: isActive },attrs:{"id":item.route,"href":href}},[_c('span',{staticClass:"navi-text"},[_vm._v(_vm._s(_vm.$t(item.label)))])])]}}],null,true)})],1)}),0)])])]),_c('div',{staticClass:"flex-row-fluid ml-lg-8 project-details"},[(_vm.$route.name === 'projectIntegrationsOverviewCreateLoginDetails')?_c('LoginDetails',{attrs:{"data":_vm.data}}):(
          _vm.$route.name === 'projectIntegrationsOverviewCreateSelectData'
        )?_c('SelectData',{attrs:{"data":_vm.data}}):(
          _vm.$route.name === 'projectIntegrationsOverviewCreateAnalyzeData'
        )?_c('AnalyzeData',{attrs:{"data":_vm.data}}):(
          _vm.$route.name === 'projectIntegrationsOverviewCreateBasicConfig'
        )?_c('BasicConfig',{attrs:{"data":_vm.data}}):(
          _vm.$route.name === 'projectIntegrationsOverviewCreateTableConfig'
        )?_c('TableConfig',{attrs:{"data":_vm.data}}):(_vm.$route.name === 'projectIntegrationsOverviewCreateSave')?_c('Save',{attrs:{"data":_vm.data}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }