<template>
  <div class="card card-custom">
    <div class="card-header py-3 d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("integrations.selectData") }}
        </h3>
      </div>
      <button class="btn btn-secondary" @click="cancel">
        {{ $t("general.cancel") }}
      </button>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body">
      <div
        v-for="(val, key) in data.selectedPossibilities"
        :key="key"
        class="row mb-5"
      >
        <div class="col-12">
          <SelectPossibility
            :possibilities="data.possibilities"
            :selected-possibility="val"
            :index="key"
          />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button class="btn btn-primary mr-2" @click="addPossibility">
            <i class="fal fa-plus"></i>
            {{ $t("integrations.addResource") }}
          </button>
          <button class="btn btn-primary" @click="removePossibility">
            <i class="fal fa-minus"></i>
            {{ $t("integrations.deleteResource") }}
          </button>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <button class="btn btn-primary" @click="prevStep">
          {{ $t("general.previousStep") }}
        </button>
        <button class="btn btn-primary" @click="nextStep">
          {{ $t("general.nextStep") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Integrations from "@/components/Projects/Integrations/Overview/integrations";
import SelectPossibility from "@/components/Projects/Integrations/Overview/Create/Steps/SelectPossibilityComponent";

export default {
  name: "ProjectsIntegrationsOverviewCreateStepsSelectData",
  components: {
    SelectPossibility
  },
  props: ["data"],
  data() {
    return {
      isBusy: false
    };
  },
  mounted() {
    if (this.data.step !== 1) {
      this.$router.push({ name: "projectIntegrationsOverview" });
    }

    this.loadPossibilities();
  },
  methods: {
    addPossibility() {
      this.data.selectedPossibilities.push({
        resource: "",
        method: ""
      });
    },
    removePossibility() {
      if (this.data.selectedPossibilities.length === 1) {
        return;
      }
      this.data.selectedPossibilities.pop();
    },
    collapse(key) {
      this.$root.$emit("bv::toggle::collapse", key);
    },
    loadPossibilities() {
      if (this.data.possibilities.length !== 0) {
        return;
      }

      this.isBusy = true;

      let id = this.$route.params.id;

      Integrations.getPossibilities(id)
        .then(response => {
          this.data.possibilities = response.data.data.possibilities;
          this.isBusy = false;
        })
        .catch(error => {
          console.log(error);
          this.isBusy = false;
        });
    },
    cancel() {
      this.$router.push({ name: "projectIntegrationsOverview" });
    },
    prevStep() {
      this.data.step -= 1;
      this.$router.push({
        name: "projectIntegrationsOverviewCreateLoginDetails"
      });
    },
    nextStep() {
      // this.data.step += 1;
      // this.$router.push({name: "projectIntegrationsOverviewCreateAnalyzeData"});
    }
  }
};
</script>

<style scoped></style>
