<template>
  <div class="card card-custom">
    <div class="card-header py-3 d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("integrations.tableConfig") }}
        </h3>
      </div>
      <button class="btn btn-secondary" @click="cancel">
        {{ $t("general.cancel") }}
      </button>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <button class="btn btn-primary" @click="prevStep">
          {{ $t("general.previousStep") }}
        </button>
        <button class="btn btn-primary" @click="nextStep">
          {{ $t("general.nextStep") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectsIntegrationsOverviewCreateStepsTableConfiguration",
  props: ["data"],
  mounted() {
    if (this.data.step !== 4) {
      this.$router.push({ name: "projectIntegrationsOverview" });
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: "projectIntegrationsOverview" });
    },
    prevStep() {
      this.data.step -= 1;
      this.$router.push({
        name: "projectIntegrationsOverviewCreateBasicConfig"
      });
    },
    nextStep() {
      this.data.step += 1;
      this.$router.push({ name: "projectIntegrationsOverviewCreateSave" });
    }
  }
};
</script>

<style scoped></style>
