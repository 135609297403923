<template>
  <div>
    <div class="row">
      <b-dropdown
        size="md"
        class="select-client-dropdown mb-1"
        variant="light"
        style="width: 100%"
      >
        <template #button-content>
          <span v-if="selectedResource !== ''" class="p-0 m-0">
            {{ selectedResource }}
          </span>
          <span v-else>
            {{ $t("integrations.selectResource") }}
          </span>
        </template>

        <b-dropdown-item
          v-for="(item, i) in possibilities"
          :key="i"
          :value="i"
          style="max-height: 45px; width: 100%"
          @click="setResource(i)"
        >
          <span>
            {{ i }}
          </span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="row">
      <b-dropdown
        size="md"
        class="select-client-dropdown"
        variant="light"
        style="width: 100%"
      >
        <template #button-content>
          <span v-if="selectedMethod !== ''" class="p-0 m-0">
            {{ selectedMethod }}
          </span>
          <span v-else>
            {{ $t("integrations.selectMethod") }}
          </span>
        </template>

        <b-dropdown-item
          v-for="(item, i) in possibilities[selectedResource]"
          :key="i"
          :value="i"
          style="max-height: 45px; width: 100%"
          @click="setMethod(i)"
        >
          <span>
            {{ i }}
          </span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectsIntegrationsOverviewCreateStepsSelectPossibilityComponent",
  props: ["possibilities", "selectedPossibility", "index"],
  data() {
    return {
      selectedResource: "",
      selectedMethod: ""
    };
  },
  mounted() {
    this.selectedResource = this.selectedPossibility["resource"];
    this.selectedMethod = this.selectedPossibility["method"];
  },
  methods: {
    setResource(resource) {
      this.selectedResource = resource;
      this.selectedMethod = "";
      this.setPossibility();
    },
    setMethod(method) {
      this.selectedMethod = method;
      this.setPossibility();
    },
    setPossibility() {
      this.$set(this.selectedPossibility, "resource", this.selectedResource);
      this.$set(this.selectedPossibility, "method", this.selectedMethod);
    }
  }
};
</script>

<style scoped></style>
