<template>
  <div class="card card-custom">
    <div class="card-header py-3 d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("integrations.loginInformation") }}
        </h3>
      </div>
      <button class="btn btn-secondary" @click="cancel">
        {{ $t("general.cancel") }}
      </button>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body">
      <div class="form">
        <div
          v-for="(val, index) in data.config"
          :key="index"
          class="form-group row align-items-center"
        >
          <label class="col-xl-2 col-lg-2 col-md-4 col-form-label">
            {{ val.label }}
            <span v-if="val.required" class="text-danger">*</span>
          </label>
          <div class="col-xl-8 col-lg-8 col-md-8">
            <b-form-input
              :type="getType(val)"
              class="form-control"
              :class="validClass(val)"
              :placeholder="val.description"
              :value="data.configValues[val.name]"
              @input="onInputChanged($event, val.name)"
            />
            <b-form-invalid-feedback :id="val.name + '-feedback'" class="ml-1">
              {{ $t("validation.required.general", { label: val.label }) }}
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-primary"
          :disabled="!configValuesValid"
          @click="testAuthentication"
        >
          {{ $t("integrations.testAuthentication") }}
        </button>
        <button
          class="btn btn-primary"
          :disabled="!data.authSuccess"
          @click="nextStep"
        >
          {{ $t("general.nextStep") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Integrations from "@/components/Projects/Integrations/Overview/integrations";

export default {
  name: "ProjectsIntegrationsOverviewCreateStepsLoginDetails",
  props: ["data"],
  data() {
    return {
      isBusy: false
    };
  },
  computed: {
    configValuesValid: function () {
      if (!Array.isArray(this.data.config)) {
        return false;
      }

      for (const config of this.data.config) {
        if (
          config.required &&
          (this.data.configValues[config.name] === undefined ||
            this.data.configValues[config.name].trim() === "")
        ) {
          return false;
        }
      }

      return true;
    }
  },
  mounted() {
    if (this.data.step !== 0) {
      this.$router.push({ name: "projectIntegrationsOverview" });
    }

    this.loadConfig();
  },
  methods: {
    loadConfig() {
      if (this.data.config.length !== 0) {
        return;
      }

      this.isBusy = true;
      this.data.authSuccess = false;

      let id = this.$route.params.id;

      Integrations.getConfig(id)
        .then(response => {
          this.data.config = response.data.data.config;
          this.isBusy = false;
        })
        .catch(error => {
          console.log(error);
          this.isBusy = false;
        });
    },
    getType(config) {
      switch (config.type) {
        case "string":
          return "text";
        default:
          return "text";
      }
    },
    validClass(config) {
      if (!config.required) {
        return "";
      }
      if (
        this.data.configValues[config.name] === undefined ||
        this.data.configValues[config.name].trim() === ""
      ) {
        return "is-invalid";
      }
      return "is-valid";
    },
    onInputChanged(value, key) {
      this.$set(this.data.configValues, key, value);
      this.data.authSuccess = false;
    },
    cancel() {
      this.$router.push({ name: "projectIntegrationsOverview" });
    },
    testAuthentication() {
      this.isBusy = true;
      this.data.authSuccess = false;

      let id = this.$route.params.id;

      Integrations.test(id, this.data.configValues)
        .then(response => {
          this.data.authSuccess =
            !!response ||
            (response.data !== undefined && response.data.data.success);
          if (this.data.authSuccess) {
            this.showSuccessToast();
          } else {
            this.showErrorToast(response.data ? response.data.data.error : "");
          }
          this.isBusy = false;
        })
        .catch(error => {
          console.log(error);
          this.isBusy = false;
        });
    },
    nextStep() {
      this.data.step += 1;
      this.$router.push({
        name: "projectIntegrationsOverviewCreateSelectData"
      });
    },
    showSuccessToast() {
      this.$swal.fire({
        icon: "success",
        title: this.$t("integrations.authSuccess"),
        showConfirmButton: true,
        confirmButtonText: this.$t("general.close"),
        showCancelButton: false
      });
    },
    showErrorToast(message) {
      this.$swal.fire({
        icon: "error",
        title: this.$t("integrations.testAuthenticationErrorTitle"),
        html: this.$t("integrations.testAuthenticationErrorMessage", {
          msg: message
        }),
        showConfirmButton: false,
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: this.$t("general.close")
      });
    }
  }
};
</script>

<style scoped></style>
