<template>
  <div>
    <div v-if="clientSelected" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <Integrations v-if="$route.name === 'projectIntegrationsOverview'" />
        <SelectConnector
          v-else-if="
            $route.name === 'projectIntegrationsOverviewSelectConnector'
          "
        />
        <Create
          v-else-if="$route.name.includes('projectIntegrationsOverviewCreate')"
        />
      </div>
    </div>

    <div v-if="!clientSelected" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <b-alert variant="danger" show>
          {{ $t("general.selectClientFirst") }}
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import Integrations from "@/components/Projects/Integrations/Overview/Table";
import SelectConnector from "@/components/Projects/Integrations/Overview/SelectConnector";
import Create from "@/components/Projects/Integrations/Overview/Create/Create";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "ProjectsIntegrationsOverviewIndex",
  components: {
    Integrations,
    SelectConnector,
    Create
  },
  computed: {
    clientSelected: function () {
      return this.$store.getters.selectedClient;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.projectIntegrations"),
        route: ""
      },
      {
        title: this.$t("menu.projectIntegrationsOverview"),
        route: "/project/integrations"
      }
    ]);
  }
};
</script>

<style scoped></style>
